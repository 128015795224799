import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
import { LinkCard } from '../components/LinkCard';
import style from './markets.module.scss';
import { DataComponent } from '../helpers/dataHelpers';

const Markets = () => {
  const data = useStaticQuery<GatsbyTypes.MarketQuery>(graphql`
   query Market {
    seo_image: file(name: { eq: "expertise-slide-3" }) {
      publicURL
    }
    header_image: file(name: { eq: "capitol" }) {
      publicURL
    }
    assets: allContentfulAsset {
      edges {
        node {
          id
          title
          localFile {
            url
            childImageSharp {
              fluid {
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
                aspectRatio
              }
            }
          }
          fluid {
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
          }
          contentful_id
        }
      }
    }
    content: allContentfulPages(filter: { name: { eq: "Markets" } }) {
      edges {
        node {
          id
          name
          slug
          content {
            json
          }
        }
      }
    }

    markets: allContentfulMarket {
      edges {
        node {
          name
          slug
          photo {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `);

  return <MarketsInner data={data}></MarketsInner>
};

export default Markets;

const MarketsInner:DataComponent<GatsbyTypes.MarketQuery> = ({data}) => {
  const markets = data.markets.edges.map(c => c.node);
  return (
    <PageWithHeader
      title="Markets"
      headerImage={data?.header_image?.publicURL}
      description="Centeva provides service excellence for the evolving federal landscape in many markets"
      image={data?.seo_image?.publicURL}
    >
      <article>
        <div className={style.list}>
          {markets.map(m => (
              <div key={m.name} className={style.cardHolder}>
                <LinkCard
                    title={m?.name || ''}
                    image={m.photo}
                ></LinkCard>
              </div>
          ))}
        </div>
      </article>
    </PageWithHeader>
  );
}